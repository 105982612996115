import {message} from 'antd';
/** @format */

import queryString from 'query-string';
import type {NotifyRequest} from 'stores/notification/types';
import type {AnyObject, AppAxiosError} from 'interfaces/shared';
import {isObjectLike} from 'lodash';
import {notification} from 'antd';

/**
 * @param time miliseconds
 * @default 3000
 */
export const sleep = (time = 3000) => new Promise(r => setTimeout(r, time));

export const getInitialsName = (name: string): string => {
  if (!name) return '';
  const words = name.split(' ');
  let result = '';
  for (const word of words) {
    result += word.charAt(0).toUpperCase();
  }
  if (!result) {
    console.warn('Could not get abbr from name');
    result = name;
  }
  return result;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const cValue = parseInt(c.join(''), 16);
    const r = (cValue >> 16) & 255;
    const g = (cValue >> 8) & 255;
    const b = cValue & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  throw new Error('Mã màu hex không hợp lệ');
};

export const isFile = (value: any): boolean => value instanceof File || value instanceof Blob;

export const transformPostData = (object: AnyObject = {}) => {
  const newObject: AnyObject = {};
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      newObject[key] = JSON.stringify(value);
    } else {
      newObject[key] = value;
    }
  }
  return queryString.stringify(newObject);
};

export const transformPostFormData = (object: AnyObject | FormData = {}): FormData => {
  if (object instanceof FormData) {
    return object;
  }
  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (isObjectLike(value)) {
      if (Array.isArray(value) && value.every(isFile)) {
        value.forEach((v: any) => {
          formData.append(key, v);
        });
      } else if (isFile(value)) {
        formData.append(key, value);
      } else {
        formData.append(key, JSON.stringify(value));
      }
    } else if (value != null) {
      formData.append(key, value);
    }
  }
  return formData;
};

export const checkStringType = (input: string) => {
  const num = Number(input);
  if (!Number.isNaN(num) && input.trim() !== '') {
    return false;
  } else {
    return true;
  }
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const removeUndefinedObject = (obj: any) => {
  if (!obj) {
    return {};
  }
  Object.keys(obj).forEach(k => {
    if (obj[k] === '' || obj[k] === null || obj[k] === undefined) {
      delete obj[k];
    }
    if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      removeUndefinedObject(obj[k]);
    }
  });
  return obj;
};

export const getMessageType = (contentEN: string, contentVN: string): NotifyRequest['message_type'] => {
  const hasContentEn = contentEN !== undefined && contentEN !== '';
  const hasContentVn = contentVN !== undefined && contentVN !== '';
  return hasContentEn && hasContentVn ? 'news' : 'noti';
};

export const DEFAULT_LOCALES = 'en-EN';
export const NUMBER_FORMAT = new Intl.NumberFormat(DEFAULT_LOCALES, {maximumFractionDigits: 0});
export const convertCurrency = (currency?: number, suffix = '₫', locales = DEFAULT_LOCALES) => {
  const _formatter =
    locales === DEFAULT_LOCALES ? NUMBER_FORMAT : new Intl.NumberFormat(locales, {minimumFractionDigits: 0, maximumFractionDigits: 0});
  if (currency === undefined || currency === 0) {
    return `0 ${suffix}`;
  }
  return `${_formatter.format(currency)} ${suffix}`;
};

export const mapValueLabel = <T, KV extends keyof T, KL extends keyof T>(list: T[] | undefined, keyValue: KV, keyLabel: KL) => {
  return list?.map(x => ({
    ...x,
    value: x[keyValue],
    label: x[keyLabel],
  }));
};

export function generateRandomDigits(count: number) {
  let digits = '';
  for (let i = 0; i < count; i++) {
    let randomDigit = Math.floor(Math.random() * 10);
    digits += randomDigit;
  }
  return digits;
}

export function generateRandomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function genAccountsAndPasswords(count: number) {
  const accounts = [];
  for (let i = 0; i < count; i++) {
    const randomString = generateRandomString(6);
    accounts.push({
      name: `accountGenerate${randomString}@eboost.vn`,
      email: `accountGenerate${randomString}@eboost.vn`,
      password: `accountGenerate${randomString}@eboost.vn`,
      phoneNumber: '0123456789',
      brand: 'api/brands/1',
      brandModel: 'api/brand_models/1',
      language: 'api/languages/EN',
      street: 'Hcm',
      city: 'Hcm',
      country: 'VN',
      state: 'Hcm',
      postalCode: '70000',
    });
  }
  return accounts;
}

export const handleNotifyErrorApi = (error: Error) => {
  let err = error as AppAxiosError;
  if (err.response) {
    notification.error({
      message: err.code,
      showProgress: true,
      description: err.response?.data.message,
      duration: 5,
    });
  }
};

export const handleNotifyErrorApiPartner = (error: Error) => {
  try {
    let err = error as AppAxiosError;
    notification.error({duration: 5, message: err.code, description: err.response?.data.errors[0].message});
  } catch (error) {
    console.log('🚀 ~ handleNotifyErrorApiPartner ~ error:', error);
  }
};

import {QueryClient, keepPreviousData} from '@tanstack/react-query';
import type {ApiResponse} from 'interfaces/shared';
import type {AxiosError} from 'axios';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError<ApiResponse & {data?: unknown}>;
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      gcTime: Infinity,
      staleTime: Infinity,
      placeholderData: keepPreviousData,
    },
  },
});

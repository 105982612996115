/** @format */

import './style.css';
import 'antd/dist/reset.css';
import 'ckeditor5/ckeditor5.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {mainRoutes} from 'routes/router';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {THEME_ANTD} from 'themes/color';
import {graphQlClient} from 'graphql/connect';
import {ApolloProvider} from '@apollo/client';
import {Provider as ReduxProvider} from 'react-redux';
import {ConfigProvider as AntdThemesProvider} from 'antd';
import {store} from 'stores';
import {QueryClientProvider} from '@tanstack/react-query';
import {queryClient} from 'utils/queries';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter(mainRoutes);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <AntdThemesProvider theme={THEME_ANTD}>
        <ApolloProvider client={graphQlClient}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ApolloProvider>
      </AntdThemesProvider>
    </ReduxProvider>
  </React.StrictMode>,
);
